<template>
	<div class="inner">
		<div class="container">
			<div class="car-btn blf tc ft24">直播间</div>
			<el-row :gutter="50">
				<el-col :span="12">
					<div class="mt50 pt50">
						<img src="../assets/logo2.png" width="168px" />
						<div class="ft36 pt12 pb40">{{contact.name}}</div>
						<div class="tl l20 ft22 bl9 pb40 mb30">
							我们主要从事平行进口汽车的进口销售业务<br />
							进口车辆全部自营自销无代理业务<br />
							稳定的丰田一级供应商保证货源<br />
							销售网络遍布全国各个城市。
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<img src="../assets/phone-img.png" width="354" />
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { contactInfo } from '@/api/index.js'
	export default {
		data() {
			return {
				contact:{
					name: '',
					companyTel: '',
					email: '',
					webSite: '',
					address: '',
					customerPhone: '',
					copyright: ''
				},
			}
		},
		created(){
			this.getContactInfo()
		},
		methods: {
			getContactInfo(){
				contactInfo().then(res=>{
					if(res.code === 200){
						this.contact = {
							name: res.data.name,
							companyTel: res.data.companyTel,
							email: res.data.email,
							webSite: res.data.webSite,
							address: res.data.address,
							customerPhone: res.data.customerPhone,
							copyright: res.data.copyright
						}
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.inner {
		background-position: center;
		background-size: cover;
		background-image: url(../assets/carvideo-bg.png);
		padding: 40px 0;
	}

	.car-btn {
		line-height: 70px;
		background-color: #b4232f;
		border-radius: 50px;
		background-image: url(../assets/btn-bg.png);
		background-position: center;
		background-size: cover;
		margin-bottom: 40px;
	}
</style>
